<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="date"
        v-bind="$attrs"
        class="flex-grow-1"
        prepend-inner-icon="mdi-calendar"
        :placeholder="placeholder"
        outlined
        hide-details="auto"
        readonly
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
  </v-menu>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "Date",
  props: {
    value: String,
  },
  data: () => ({
    date: "",
    menu: false,
  }),
  created() {
    this.date = this.value ?? this.date;
  },
  computed: {
    placeholder() {
      return new dayjs().format("YYYY-MM-DD");
    },
  },
  watch: {
    date(newDate) {
      this.$emit("input", newDate);
    },
    value(newValue) {
      this.date = newValue;
    },
  },
};
</script>

<style lang="scss" scoped></style>
